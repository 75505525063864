import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Helmet } from 'react-helmet'
import { CaretRight } from 'phosphor-react'
import Faq from '../components/Faq'
import PageTitle from '../components/Blocks/PageTitle'

const PageTemplate = ({ title, content, heroImage, vehicleBrands, faq }) => (
  <div className="vehicles">
    <img src={heroImage.mediaItemUrl} className="w-full" />
    <PageTitle title="Top Vehicles For Lease" />
    <div className="mt-4 px-8 md:px-20 p-10 border-b border-brand-green max-w-screen-xl mx-auto">
      <h2 className="text-center">D{'&'}M Leasing Inventory</h2>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-center my-10">
        {vehicleBrands.nodes.map(vehicle => (
          <Link
            to={vehicle.uri}
            key={vehicle.id}
            className=" text-brand-blue uppercase my-2 flex items-center"
          >
            {vehicle.title} <CaretRight />
          </Link>
        ))}
      </div>
    </div>
    <div
      className="transition-all p-8 md:p-20 pb-4 md:pb-4"
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <Faq title={faq?.title} items={faq?.faqs} />
  </div>
)

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, pageContext }) => {
  const { wpPage: page, wpMediaItem, allWpPage } = data

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Does D&M really lease any make, any model?","acceptedAnswer":{"@type":"Answer","text":"Yes! D&amp;M Leasing will deliver any make, any model vehicle right to your doorstep. No matter what type of trim, color or body style, options and integrated gadgets you want in a vehicle, D&amp;M can get it. We have been around since 1976 and are the largest consumer leasing company in America. Browse the vehicles above to find your next lease."}},{"@type":"Question","name":"How to calculate your car lease payment?","acceptedAnswer":{"@type":"Answer","text":"D&amp;M Leasing has set up over 40,000 active leases in some of the most competitive markets in the United States for our clients. We leverage our position in the market to get you the very best deal and have the lowest rates of any consumer lease on the market."}},{"@type":"Question","name":"Do I have to put a down payment on my car lease?","acceptedAnswer":{"@type":"Answer","text":"No! The vast majority of D&amp;M clients make no down payment and lease with up to 50% lower payments than financing. "}},{"@type":"Question","name":"Does D&M offer high mileage lease programs?","acceptedAnswer":{"@type":"Answer","text":"Many years ago leasing was created for high mileage drivers. There are leases on the market with strict mileage restrictions, but not the EZ Lease. With D&amp;M’s EZ Lease, get flexible mileage, the lowest rates, and best trade-in values around."}}]}`}
        </script>
      </Helmet>
      <PageTemplate
        title={page.title}
        content={page.content}
        heroImage={wpMediaItem}
        vehicleBrands={allWpPage}
        faq={page.faq}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query VehiclesPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
    wpMediaItem(id: { eq: "cG9zdDoxNTEwMQ==" }) {
      id
      mediaItemUrl
    }
    allWpPage(
      filter: { parentDatabaseId: { eq: 248 } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        uri
        id
      }
    }
  }
`
